import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import profilePictureDefault from '../../../assets/profile_default.jpg';
import './navi.css';
import { Chip, Menu, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Logout, Settings, AccountBox } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import RoleGuard from '../../router/roleGuard';
import { USER } from '../../../constants/user';
import storeAuth from '../../../store/auth';
import storeDashboard from '../../../store/dashboard/dashboard';

const drawerWidth = 240;
const headerHeight = 64;

function ResponsiveDrawer(props) {
    const pathName = useLocation().pathname;
    const { loggedInUser, logoutUser, AUTH_DATA } = storeAuth((state) => state);
    const { selectedApp, setApp } = storeDashboard((state) => state);
    const navigate = useNavigate();

    const { window, children } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // profile dropdown
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    // handle logout click
    const callLogutUser = () => {
        logoutUser();
        setApp(null);
        localStorage.removeItem(AUTH_DATA);
        navigate('/login');
    };

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {SidebarData.map((item, index) => (
                    <RoleGuard allowedRoles={item.allowedRoles} navigate={false} renderChildren={true} key={index}>
                      <ListItem key={index} disablePadding className={[
                                    item.cName,
                                    pathName === item.path ? 'active' : '',
                                ]
                                    .filter((e) => !!e)
                                    .join(' ')} >
                          
                          <Link to={item.path} >
                            <ListItemButton >
                                <ListItemIcon style={{minWidth: '40px'}}>
                                  {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                          </Link>
                      </ListItem>
                    </RoleGuard>
                  ))}
            </List>
        </div>
    );

    const header = (
        <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    height: { sm: `${headerHeight}px`}
                }}>
                <Toolbar>
                    <Chip label={selectedApp?.name || "No app selected"} sx={{left: 0}}/>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        display="flex"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{margin: '0 auto'}}>
                        BrrmChat Dashboard
                    </Typography>

                    <IconButton
                        sx={{ marginLeft: 'auto' }}
                        aria-label={loggedInUser?.name}
                        onClick={handleClick}>
                        <Tooltip title={loggedInUser?.name}>
                            <Avatar
                                alt={loggedInUser?.name}
                                src={profilePictureDefault}
                            />
                        </Tooltip>
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                        }}>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                              <AccountBox fontSize="small" /> 
                            </ListItemIcon>
                            Profile
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                        <MenuItem onClick={callLogutUser}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>

                </Toolbar>
            </AppBar>
    )

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }} className="navi-box">
            <CssBaseline />
            {header}
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}>
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                    open>
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                marginTop={`${headerHeight}px`}
                width={`calc(100% - ${drawerWidth}px)`}
                sx={{
                    flexGrow: 1,
                    p: 3,
                    // width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}>
                {children}
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
