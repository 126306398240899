import React from "react";
import { Button, Card, CardActions, CardContent, CardMedia, Divider, Paper, Skeleton, Typography } from "@mui/material";


const AppPreview = ({appObj, fetching=true}) => {

    console.log("[AppPreview] rendered: ", appObj)
    console.log("[AppPreview] fetching: ", fetching)

    return (
        <>
        <Paper elevation={3} sx={{padding: 2, marginTop: 2}} >
            <Typography gutterBottom variant="h5" component="div">{!fetching ? 
                `App ${appObj?.name}` : 
                <Skeleton />}
            </Typography>

            <Typography gutterBottom variant="body1" component="div">{!fetching ? 
                `CreatedAt ${appObj?.createdAt}` : 
                <Skeleton />}
            </Typography>
            <Divider />
        </Paper>
        </>
    );
};


export default AppPreview;