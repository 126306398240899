import { create } from 'zustand';

const AUTH_DATA = 'vmdkjdfhfgiu348r838fdj39';
const REMEMBER_ME = 'fjif2ijjiofj209f2j93f2';

const getAuth = () => {
  const auth =
    JSON.parse(localStorage.getItem(AUTH_DATA)) ||
    JSON.parse(sessionStorage.getItem(AUTH_DATA)) ||
    null;

  return {
    token: auth?.token || null,
    _id: auth?._id || null,
  };
};


const isLoggedIn =
  !!localStorage.getItem(AUTH_DATA) || !!sessionStorage.getItem(AUTH_DATA);

const rememberMe = !!localStorage.getItem(REMEMBER_ME) || false;

const storeAuth = create((set) => ({
  AUTH_DATA: AUTH_DATA,
  REMEMBER_ME: REMEMBER_ME,
  auth: getAuth(),
  isLoggedIn,
  loggedInUser: null,
  rememberMe,
  setUser: (data) =>
    set(() => ({
      loggedInUser: data,
    })),
  loginUser: ({ data, auth, rememberMe }) =>
    set(() => ({
      isLoggedIn: true,
      loggedInUser: data.user,
      auth,
    })),
  logoutUser: () =>
    set({
      auth: {
        token: null,
        _id: null,
      },
      isLoggedIn: false,
      loggedInUser: null,
      rememberMe: false,
    }),
}));

export default storeAuth;
