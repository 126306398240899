import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { addGroup } from '../../../api/brrmchat/group/group';
import storeDashboard from '../../../store/dashboard/dashboard';
import {
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material';
import { addUsersToGroup, getAppUsers, removeUsersFromGroup } from '../../../api/brrmchat/user/user';

export default function DialogAddUserToGroup({
    open = true,
    onClose,
    onSubmit,
    groupId,
    usersFromGroup,
    appUsers,
}) {
    const { selectedApp } = storeDashboard((state) => state);
    const [users, setUsers] = useState(null);
    const [groupUsers, setGroupUsers] = useState(null);


    console.log('[DialogAddUserToGroup] rendered users: ', users);
    console.log('[DialogAddUserToGroup] rendered groupUsers: ', groupUsers);
    console.log('[DialogAddUserToGroup] rendered appUsers: ', appUsers);
    console.log('[DialogAddUserToGroup] rendered usersFromGroup: ', usersFromGroup);

    useEffect(() => {
        if(appUsers && usersFromGroup) {
            const usersFromGroupIds = usersFromGroup.map((usr) => usr.id);
            const newUsers = appUsers.filter((usr) => !usersFromGroupIds.includes(usr.id));
            setUsers(newUsers);
            setGroupUsers(usersFromGroup);
        }
    }, [appUsers, usersFromGroup]);

    useEffect(() => {

    }, [users, groupUsers])

    const mutationAddUsersToGroup = useMutation({
        mutationKey: ['mutationAddUsersToGroup'],
        mutationFn: addUsersToGroup,
        onSuccess: (data) => {
            console.log('Success AddUsersToGroup', data);
            const userIds = users?.map((usr) => usr.id);
            console.log('[DialogAddGroup] before mutate: ', userIds, groupId);
            mutationRemoveUsersFromGroup.mutate({ groupId, userIds });
        },
        onError: (err) => {
            console.log('Error AddUsersToGroup: ', err);
            toast.error(err);
        },
    });

    const mutationRemoveUsersFromGroup = useMutation({
        mutationKey: ['mutationRemoveUsersFromGroup'],
        mutationFn: removeUsersFromGroup,
        onSuccess: (data) => {
            console.log('Success RemoveUsersFromGroup', data);
            onSubmit(data);
        },
        onError: (err) => {
            console.log('Error RemoveUsersFromGroup: ', err);
            toast.error(err);
        },
    })

    // useEffect(() => {
    //     console.log("[DialogAddUserToGroup] effect users: ", users)
    //     console.log("[DialogAddUserToGroup] effect groupUsers: ", groupUsers)
    //     setSelectedUsers(groupUsers);
    //     setUsers(users);
    // }, [groupUsers, users]);

    const validateSubmit = () => {
        if (groupUsers === usersFromGroup && users === appUsers) {
            toast.error('No changes made');
        }
        // mutationAddGroup.mutate(formDataRef.current);
        const userIds = groupUsers.map((usr) => usr.id);
        console.log('[DialogAddGroup] before mutate: ', userIds, groupId);
        mutationAddUsersToGroup.mutate({ groupId, userIds });
    };

    const handleAddUserToQueue = (userObj) => {
        console.log('Add to queue userObj: ', userObj);
        const newUsers = users.filter(
            (usr) => usr.id !== userObj.id
        );
        setUsers(newUsers);

        setGroupUsers([...(groupUsers || []), userObj]);
    };

    const handleRemoveUserToQueue = (userObj) => {
        console.log('Remove from queue userObj: ', userObj);
        const newGroupUsers = groupUsers.filter(
            (usr) => usr.id !== userObj.id
        );
        setGroupUsers(newGroupUsers);

        setUsers([...(users || []), userObj]);
    };

    const handleClose = () => {
        setUsers(null);
        setGroupUsers(null);
        onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                }}>
                <DialogTitle>Dialog Edit Group Users</DialogTitle>
                <DialogContent>
                    {groupUsers && users && (
                        <List
                            sx={{ maxHeight: '400px' }}
                            component="nav"
                            aria-label="mailbox folders">
                            {users?.map((usr) => (
                                <ListItem
                                    key={usr.id}
                                    button
                                    onClick={() => handleAddUserToQueue(usr)}>
                                    <ListItemText
                                        primary={usr?.name}
                                        secondary={usr?.email}
                                        divider
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
                <Divider />
                <DialogContent>
                    {groupUsers && (
                        <Typography gutterBottom>
                            {groupUsers?.map((usr) => (
                                <Chip
                                    key={usr.id}
                                    label={usr.name}
                                    variant="outlined"
                                    onDelete={() =>
                                        handleRemoveUserToQueue(usr)
                                    }
                                />
                            ))}
                        </Typography>
                    )}
                    {/* <TextField
                        error={hasError && !!formDataRef.current.titleError}
                        helperText={(hasError && !!formDataRef.current.titleError) ? formDataRef.current.titleError : "" }
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Group title"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.title = event.target.value 
                            setHasError(false)
                        }}
                    />
                    <TextField
                        error={hasError && !!formDataRef.current.uniqueIdError}
                        helperText={(hasError && !!formDataRef.current.uniqueIdError) ? formDataRef.current.uniqueIdError : "" }
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Group unique id"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.uniqueId = event.target.value 
                            setHasError(false)
                        }}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={validateSubmit}>Update</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
