import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import storeAuth from '../../../store/auth';
import { toast } from 'react-toastify';
import { Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import storeDashboard from '../../../store/dashboard/dashboard';
import { getAppUsers } from '../../../api/brrmchat/user/user';
import UserCard from '../shared/userCard';
import BaseSection from '../shared/baseSection';
import DialogAddUser from './dialogAddUser';

const Users = () => {
    console.log('[Users] rendered');
    const { selectedApp, selectedAppId } = storeDashboard((state) => state);
    const { auth, loggedInUser } = storeAuth((state) => state);

    const [selectedGroup, setSelectedGroup] = useState(false);
    const [isDialogAddUserOpen, setIsDialogAddUserOpen] = useState(false);

    const queryAppUsers = useQuery({
        queryKey: ['appUsers'],
        queryFn: () => getAppUsers(selectedApp?.id),
        enabled: !!selectedApp?.id,
    });

    useEffect(() => {
        if (!queryAppUsers.isFetching && queryAppUsers.isError) {
            toast.error('Error getting users.');
        } 
    }, [queryAppUsers.isFetching]);

    useEffect(() => {
        queryAppUsers.refetch();
    }, [selectedAppId]);

    const handleAddUser = () => {
        setIsDialogAddUserOpen(true);
    };

    const handleAddGroupClose = () => {
        setIsDialogAddUserOpen(false);
    };

    const handleAddGroupSubmit = (groupData) => {
        console.log('AddApp Dialog submit: ', groupData);
        setIsDialogAddUserOpen(false);
    };



    return (
        <>
            <DialogAddUser
                key={"dialog-add-group"}
                open={isDialogAddUserOpen}
                onClose={handleAddGroupClose}
                onSubmit={handleAddGroupSubmit}
            />
            <BaseSection
                key={"section-users"}
                actionButton={
                    <IconButton
                        onClick={handleAddUser}
                        sx={{ float: 'right' }}>
                        <AddCircleIcon />
                    </IconButton>
                }
                title={'Users'}
                subtitle={queryAppUsers?.data?.length > 0 ? `[${queryAppUsers?.data?.length} total]` : ''}
                noChildrenMessage={"No users, feel free to create them."}
            >
                {queryAppUsers?.data?.length > 0 && 
                    <Grid container spacing={1} xs={12} item sx={{alignItems: 'center'}}>
                        {queryAppUsers?.data?.map((usr) => (
                            <>
                                <UserCard
                                    key={usr?.id}
                                    userObj={usr}
                                />
                            </>
                        ))}
                    </Grid>
                }
            </BaseSection>
        </>
    );
};

export default Users;
