import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#e2d9a3',
            light: '#eee5b3',
            dark: '#d1c47b',
        },
        secondary: {
            main: '#aae2a3',
            light: '#bff0b8',
            dark: '#92d48a',
        },
        background: {
            paper: '#1b1b27',
            default: '#1b1b27'
        }
    }
})