import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getUserSelf } from '../../api/brrmchat/user/user';
import globalLoadingStore from '../../store/loading/globalLoading';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import storeAuth from '../../store/auth';
import storeDashboard from '../../store/dashboard/dashboard';
import { getApp } from '../../api/brrmchat/app/app';

const PrivateRoute = () => {
    const { auth, loggedInUser, setUser } = storeAuth((state) => state);
    const { selectedApp, setApp } = storeDashboard((state) => state);

    const { setIsLoading, setMessage } = globalLoadingStore((state) => state);

    const navigate = useNavigate();

    useEffect(() => {
        if (auth._id === null || auth.token === null) {
            navigate('/login');
        }
    }, []);
    const queryLogin = useQuery({
        queryKey: ['loggedInUser'],
        queryFn: () => getUserSelf(auth._id),
        enabled: !!auth._id && loggedInUser !== null,
    });
    const queryGetApp = useQuery({
        queryKey: ['getBaseApp'],
        queryFn: () => getApp(selectedApp.id),
        enabled: !!auth._id && loggedInUser !== null && !!selectedApp.id,
        
    });

    useEffect(() => {
        setMessage('Initializing...');
        setIsLoading(queryLogin.isFetching);
        if (!queryLogin.isFetching && queryLogin.isError) {
            navigate('/login');
        } else if (!queryLogin.isFetching && !queryLogin.isError) {
            setUser(queryLogin.data);
        }
    }, [queryLogin.isFetching]);

    useEffect(() => {
      if(queryGetApp.isFetched) {
        setApp(queryGetApp.data);
      }
    }, [queryGetApp.isFetched]);

    return <>{loggedInUser && <Outlet />}</>;
};

export default PrivateRoute;
