import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PeopleIcon from '@mui/icons-material/People';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GroupsIcon from '@mui/icons-material/Groups';
import { useState, useRef } from 'react';
import BaseCard from './baseCard';

const GroupCard = ({
    groupObj,
    onGroupSelected,
    selected = false,
    onGroupUsersSelected,
}) => {
    const handleEditGroup = () => {
        console.log('[GroupCard] edit clicked: ', groupObj);
    };
    const [actionsOpen, setActionsOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleActionsToggle = () => {
        setActionsOpen((prevActionsOpen) => !prevActionsOpen);
    };

    const handleActionsClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setActionsOpen(false);
    };

    function handleActionsListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setActionsOpen(false);
        } else if (event.key === 'Escape') {
            setActionsOpen(false);
        }
    }

    const handleActionEdit = () => {};

    const handleActionDelete = () => {};

    return (
        <>
            <BaseCard 
                id={groupObj?.id}
                selected={selected}
                avatar={
                    <Avatar aria-label={groupObj?.name}>
                        <GroupsIcon />
                    </Avatar>
                }
                actionMenuItems={
                    <>
                        <MenuItem
                            onClick={
                                handleActionEdit
                            }
                            disableRipple>
                            <EditIcon />
                            Edit
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem
                            onClick={
                                handleActionDelete
                            }
                            disableRipple>
                            <DeleteForeverIcon />
                            Delete
                        </MenuItem>
                    </>
                }
                title={groupObj?.title}
                subtitle={groupObj?.createdAt}
                cardActions={groupObj ? (
                    <>
                    <Tooltip title={"See users"}>
                        <Button
                            size="small"
                            onClick={() => onGroupUsersSelected(groupObj)}>
                            <PeopleIcon />
                        </Button>
                    </Tooltip>
                    </>
                ) : (
                    <>
                        <Skeleton animation="wave" height={1} width="10%" />
                    </>
                )}
            >

                <List sx={{ width: '100%' }}>
                    <ListItem>
                        {groupObj ? (
                            <ListItemText secondary={groupObj?.uniqueId} primary="Group Unique Id" />
                        ) : (
                            <Typography
                                variant="body2"
                                color="text.secondary">
                                {groupObj?.uniqueId}
                            </Typography>
                        )}
                    </ListItem>
                </List>

            </BaseCard>
        </>
    );
};

export default GroupCard;
