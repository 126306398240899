import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { addGroup } from '../../../api/brrmchat/group/group';
import storeDashboard from '../../../store/dashboard/dashboard';

export default function DialogAddGroup({open = true, onClose, onSubmit}) {

    console.log("[DialogAddGroup] rendered");
    const { selectedApp, selectedAppId } = storeDashboard((state) => state);
    const [ hasError, setHasError] = useState(false);
    const formDataRef = useRef({
        appId: selectedApp.id,
        title: null,
        titleError: null,
        uniqueId: null,
        uniqueIdError: null,
    });

    useEffect(() => {
        console.log("uhvacena promena hasError: ", hasError);
    }, [hasError])

    const validateSubmit = () => {
        if(formDataRef?.current?.title?.length < 8) {
            formDataRef.current.titleError = "Must be at least 8 character long.";
            setHasError(true);
            return;
        } 
        console.log("[DialogAddGroup] before mutate: ",formDataRef?.current)
        mutationAddGroup.mutate(formDataRef.current);
    }

    const mutationAddGroup = useMutation({
        mutationFn: addGroup,
        onSuccess: (data) => {
            console.log('Success add Group', data);
            onSubmit(data);
        },
        onError: (err) => {
            console.log('Error add Group: ', err);
            toast.error(err);
        },
    });
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Dialog Add Group</DialogTitle>
                <DialogContent>
                    <TextField
                        error={hasError && !!formDataRef.current.titleError}
                        helperText={(hasError && !!formDataRef.current.titleError) ? formDataRef.current.titleError : "" }
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Group title"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.title = event.target.value 
                            setHasError(false)
                        }}
                    />
                    <TextField
                        error={hasError && !!formDataRef.current.uniqueIdError}
                        helperText={(hasError && !!formDataRef.current.uniqueIdError) ? formDataRef.current.uniqueIdError : "" }
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Group unique id"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.uniqueId = event.target.value 
                            setHasError(false)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={() => { validateSubmit(formDataRef.current)}}>Add</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
