import React, { useState } from 'react';
import Navigation from './navigation/navigation';
import { Outlet } from 'react-router-dom';
import ResponsiveDrawer from './navigation/navi';
import { ToastContainer } from 'react-toastify';


const Dashboard = () => {
    

  return (
    <>
        {/* <Navigation /> */}
        <ResponsiveDrawer >

          <Outlet />
        </ResponsiveDrawer>
    </>
  );
};

export default Dashboard;