import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from '@tanstack/react-query';
import { addApp } from '../../../api/brrmchat/app/app';
import { toast } from 'react-toastify';

export default function DialogAddApp({open = true, onClose, onSubmit}) {

    console.log("[DialogAddApp] rendered");
    const [ hasError, setHasError] = useState(false);
    const formDataRef = useRef({
        name: null,
        nameError: null,
        fcmParameters: null,
        fcmParametersError: null,
    });

    useEffect(() => {
        console.log("uhvacena promena", formDataRef.current.nameError)
    }, [hasError])

    const validateSubmit = (appObj) => {
        if(appObj.name?.length < 8) {
            formDataRef.current.nameError = "Must be at least 8 character long.";
            console.log(!!formDataRef.current.nameError)
            setHasError(true);
            return;
        } 
        console.log("Before post: ", formDataRef.current);
        mutationAddApp.mutate(formDataRef.current);
    }

    const mutationAddApp = useMutation({
        mutationFn: addApp,
        onSuccess: (data) => {
            console.log('Success add App', data);
            onSubmit(data);
        },
        onError: (err) => {
            console.log('Error add App: ', err);
            toast.error(err);
        },
    });
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Dialog Add Application</DialogTitle>
                <DialogContent>
                    <TextField
                        error={hasError && !!formDataRef.current.nameError}
                        helperText={(hasError && !!formDataRef.current.nameError) ? formDataRef.current.nameError : "" }
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Application name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.name = event.target.value 
                            setHasError(false)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={() => { validateSubmit(formDataRef.current)}}>Add</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
