import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { getApps, getApp } from '../../../api/brrmchat/app/app';
import storeAuth from '../../../store/auth';
import { toast } from 'react-toastify';
import AppCard from './appCard';
import { Grid, IconButton } from '@mui/material';
import AppPreview from './appPreview';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DialogAddApp from './dialogAddApp';
import storeDashboard from '../../../store/dashboard/dashboard';
import BaseSection from '../shared/baseSection';

const Apps = () => {
    console.log('[Apps] rendered'); 
    const { selectedApp:selectedDefaultApp, setApp: setDefaultApp } = storeDashboard((state) => state);
    const { auth, loggedInUser } = storeAuth((state) => state);
    const [ selectedAppId, setSelectedAppId ] = useState(null);
    const [ selectedApp, setSelectedApp ] = useState(null);
    const [ isDialogAddAppOpen, setIsDialogAddAppOpen ] = useState(false);
    
    const queryApps = useQuery({
        queryKey: ['getApps'],
        queryFn: () => getApps('full'),
        enabled: loggedInUser !== null,
    });
    const queryApp = useQuery({
        queryKey: ['getApp', selectedAppId],
        queryFn: () => getApp(selectedAppId),
        enabled: !!selectedAppId,
    });

    
    useEffect(() => {
        if (!queryApps.isFetching && queryApps.isError) {
            toast.error("Error getting apps.")
        } else if (!queryApps.isFetching && !queryApps.isError && queryApps.data) {
            console.log("getovano allApps: ", queryApps.data)


        }
    }, [queryApps.isFetching]);

    useEffect(() => {
        if(selectedAppId) {
            queryApp.refetch();
        }
    }, [selectedAppId])

    useEffect(() => {
        if (!queryApp.isFetching && queryApp.isError) {
            toast.error("Error getting app: ", selectedAppId)
        } else if (!queryApp.isFetching && !queryApp.isError && queryApp.data) {
            console.log("getovan app: ", queryApp.data)
            setSelectedApp(queryApp.data);

        }
    }, [queryApp.isFetching]);

    useEffect(() => {
    }, [selectedDefaultApp]);

    const handleAppSelect = (appId) => {
        if(appId !== selectedAppId) {
            console.log("[Apps] app clicket: ", appId)
            setSelectedAppId(appId);
            setSelectedApp(null);
        }
    }

    const handleAddApp = () => {
        console.log("Add APP")
        setIsDialogAddAppOpen(true);
    }

    const handleAddAppClose = () => {
        console.log("AddApp Dialog closed");
        setIsDialogAddAppOpen(false);
    }

    const handleAddAppSubmit = (appData) => {
        console.log("AddApp Dialog submit: ", appData);
        setIsDialogAddAppOpen(false);
    }

    const handleChangeDefaultApp = (appObj) => {
        setDefaultApp(appObj);
    }

    return (
        <>
        <DialogAddApp open={isDialogAddAppOpen} onClose={handleAddAppClose} onSubmit={handleAddAppSubmit}/>
        <BaseSection 
            title="Applications"
            actionButton={
                <>
                    <IconButton onClick={handleAddApp} sx={{float: 'right'}}>
                        <AddCircleIcon />
                    </IconButton>
                </>
            }
            noChildrenMessage={"No applications."}
        >
        <Grid container spacing={1} xs={12} item sx={{alignItems: 'center'}}>
            {queryApps.data?.map((app) => (
                <AppCard appObj={app} onAppSelect={handleAppSelect} selected={selectedAppId == app.id} key={app?.id} onDefaultAppSelect={handleChangeDefaultApp}/>
            ))}
        </Grid>
        </BaseSection>
        {selectedAppId && 
            <AppPreview appObj={selectedApp} fetching={queryApp.isFetching}/>
        }
        </>
    );
};

export default Apps;
