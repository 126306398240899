import React from "react";
import useAuthStore from "../../store/auth";
import { Navigate, Outlet } from "react-router-dom";


const RoleGuard = ({allowedRoles, navigate=true, renderChildren=false, children}) => {
    const { auth, loggedInUser } = useAuthStore((state) => state);

    return !!allowedRoles.find((role) => loggedInUser.role === role) ? (
        renderChildren ? children : <Outlet /> 
    ) : (
        navigate && <Navigate to={'/'} />
    )

};


export default RoleGuard;