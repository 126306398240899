import React from "react";
import { Button,  Checkbox, FormControlLabel, Skeleton, Typography } from "@mui/material";
import storeDashboard from "../../../store/dashboard/dashboard";
import { CheckBox } from "@mui/icons-material";
import BaseCard from "../shared/baseCard";


const AppCard = ({appObj, onAppSelect, selected=false, onDefaultAppSelect}) => {

    const { selectedApp, setApp } = storeDashboard((state) => state);

    const handleChangeDefaultApp = (appObj) => {
        onDefaultAppSelect(appObj);
    }

    return (
        <>
        <BaseCard 
          title={appObj?.name ? (
            <Typography sx={{cursor: 'pointer'}} gutterBottom variant="h6" component="div" onClick={() => onAppSelect(appObj?.id)}>
                {appObj?.name}
            </Typography>
          ) : (
            <Skeleton animation="wave" height={'1rem'} width="10%" />
          )}
          cardActions={
            appObj ? (
                <>
                    <Button size="small">FCM</Button>
                    <Button size="small">Delete Policy</Button>
                </>
            ):(
                <>
                    <Skeleton animation="wave" height={1} width="10%" />
                    <Skeleton animation="wave" height={1} width="10%" />
                </>
            )
          }
        >
            {appObj ? (
                <>
                    <FormControlLabel control={
                        <Checkbox   
                                checked={appObj?.id === selectedApp?.id}
                                disabled={appObj?.id === selectedApp?.id}
                                onChange={() => {handleChangeDefaultApp(appObj)}}
                                sx={{float: 'right', fontSize: '1rem'}} 
                            />
                        } label="Select default" />
                    <Typography variant="body2" fontSize={'.6rem'} color="text.secondary">
                        {appObj?.createdAt}
                    </Typography>
                </>
            ):(
                <>
                    <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={30} width="300px" />
                </>
            )}
        </BaseCard>
        </>
    );
};


export default AppCard;