import axiosBase from 'axios';
import storeAuth from '../../store/auth';

const axios = axiosBase.create({
  baseURL: 'https://api.chat.brrm.eu/v1',
});

axios.interceptors.request.use((request) => {
  const { auth } = storeAuth.getState();
  // remove error if any
  // store.dispatch(errorActions.removeError())

  // add auth header with jwt if account is logged in and request is to the api url
  const token = auth.token;
  // const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
  if (token && request.url !== '/login' && request.url != '/appAuth') {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

export default axios;
