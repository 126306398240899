import { create } from 'zustand';

const globalLoadingStore = create((set) => ({
  isLoading: false,
  message: '',
  setIsLoading: (isLoading) =>
    set(() => ({
        isLoading,
    })),
  setMessage: (message) => 
    set(() => ({
      message,
    })),
}));

export default globalLoadingStore;
