import React from "react"
import globalLoadingStore from "../../../store/loading/globalLoading";
import ReactLoading from "react-loading";
import "./globalLoading.css"

const GlobalLoading = () => {
    const { isLoading, message } = globalLoadingStore((state) => state);
    console.log("[GlobalLoading] RENDERED: ", isLoading, message)
    return (
        <>
            {
                isLoading && (
                    <>
                     <div className="global-loading-screen" >
                        <ReactLoading className='global-loading-animation' type='bars' color="inherit" />
                        {(message !== '') && <div className="global-loading-message">{message}</div>}
                    </div>
                    </>
                )
            }
           
        
        </>
    )
};

export default GlobalLoading;