import axios from "../axios";

 
export async function getApps(scope=null) {
    const result = await axios.get(
        `/apps`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return result.data;
}


export async function getApp(appId) {
    const result = await axios.get(
        `/apps/${appId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    return result.data;
}


export async function addApp({name, fcmParameters, deletePolicy}) {
  //return new Promise((res) => setTimeout(res({}), 5000))
  //console.log("AXIOS", axios);
  const result = await axios.post(
    `/apps`,
    {
      name,
      ...(fcmParameters ? fcmParameters : {}),
      ...(deletePolicy ? deletePolicy : {}),
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return result.data;
}
