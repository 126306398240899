import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "../../store/auth";

function PublicRoute() {

    const navigate = useNavigate();

    const { loggedInUser, auth } = useAuthStore((state) => state);
    console.log("[PublicRoute] loggedInUser: ", loggedInUser);

    useEffect(() => {
        if(loggedInUser || (auth._id !== null && auth.token !== null)) {
            navigate('/');
        } 
    }, [loggedInUser, auth]);

    return (
        <Outlet />
    )
}

export default PublicRoute;