import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as BsIcons from 'react-icons/bs';
import { USER } from '../../../constants/user';
import AppsIcon from '@mui/icons-material/Apps';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';


export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <DashboardIcon />,
    cName: 'nav-text',
    allowedRoles: Object.values(USER.ROLE),
  },
  {
    title: 'Applications',
    path: '/apps',
    icon: <AppsIcon />,
    cName: 'nav-text',
    allowedRoles: [USER.ROLE.ROOT],
  },
  {
    title: 'Users',
    path: '/users',
    icon: <PeopleIcon />,
    cName: 'nav-text',
    allowedRoles: [USER.ROLE.ROOT, USER.ROLE.ADMIN],
  },
  {
    title: 'Groups',
    path: '/groups',
    icon: <GroupsIcon />,
    cName: 'nav-text',
    allowedRoles: Object.values(USER.ROLE),
  },
  {
    title: 'Groups 123',
    path: '/groups123',
    icon: <GroupsIcon />,
    cName: 'nav-text',
    allowedRoles: Object.values(USER.ROLE),
  },
];
