import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { login } from '../../api/brrmchat/auth/auth';
import { useMutation } from '@tanstack/react-query';
import storeAuth from '../../store/auth';
import ReactLoading from "react-loading"
import storeDashboard from '../../store/dashboard/dashboard';

const LoginForm = () => {
    const { loginUser, AUTH_DATA } = storeAuth((state) => state);
    const { setApp } = storeDashboard((state) => state);
    const { userEmail, setUserEmail } = useState('');
    const { userPass, setUserPass } = useState('');
    let loadingLogin = false;

    const onFinish = (event) => {
        event.preventDefault();
        console.log('Success:', userEmail, userPass);
    };
    const {
      register,
      handleSubmit,
      formState: { errors: formErrors },
    } = useForm();

    const onSubmit = (data) => {
        loadingLogin = true;
        mutate({email: data.userEmail, password: data.userPass});
    }

    console.log("userEmail: ", userEmail);
    const { mutate, isLoading } = useMutation({
        mutationFn: login,
        onSuccess: (data) => {
            loadingLogin = false;
            console.log('Success login', data);
            const auth = {
                token: data.token,
                _id: data.user.id,
            };
            localStorage.setItem(AUTH_DATA, JSON.stringify(auth));
            loginUser({ data, auth });
            setApp({id: data.appId});
        },
        onError: (err) => {
            loadingLogin = false;
            console.log('Error login', err);
            console.log('Error login formErrors: ', formErrors);
            formErrors.formError = err?.response?.data?.description;
        },
    });
    return (
        <>
            <div className="signin">
                <div className="content">
                    <h2>Login</h2>
                    <form className="form" onSubmit={handleSubmit((data) => onSubmit(data))}>
                        {formErrors.formError && <p className='signin-error-message'>{formErrors.formError}</p>}
                        {loadingLogin && <ReactLoading className='signin-loading' type='bars' color='inherit' />}
                        {!loadingLogin && 
                            <div className="inputBox">
                                <input
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    {...register('userEmail', { required: true})}
                                />{' '}
                                <i>Email</i>
                            </div>
                        }
                        {formErrors.userEmail && <p>Email is required.</p>}

                        {!loadingLogin && 
                            <div className="inputBox">
                                <input
                                    type="password"
                                    name="password"
                                    autoComplete="current-password"
                                    {...register('userPass', { required: true })}
                                />{' '}
                                <i>Password</i>
                            </div>
                        }
                        {formErrors.userPass && <p>Password is required.</p>}

                        <div className="links">
                            {' '}
                            <a href="#">Forgot Password</a>{' '}
                            <a href="#">Signup</a>
                        </div>

                        <div className="inputBox">
                            <input type="submit" value="Login" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default LoginForm;
