import axios from "../axios";

 
export async function getGroups(appId) {
    const result = await axios.get(
        `/apps/${appId}/groups`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return result.data;
}


export async function getGroup(appId, groupId) {
    const result = await axios.get(
        `/apps/${appId}/groups/${groupId}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return result.data;
}


export async function addGroup({appId, title, uniqueId}) {

  const result = await axios.post(
    `/apps/${appId}/groups`,
    {
      title,
      uniqueId,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return result.data;
}
