import React from "react";
import { Box, Card, CardActions, CardContent, CardHeader, ClickAwayListener, Divider, Grow, IconButton, MenuList, Paper, Popper } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRef, useState } from "react";


const BaseCard = ({children, actionMenuItems, avatar, selected=false, title, subtitle, cardActions, id}) => {

    const anchorRef = useRef(null);

    const [actionsOpen, setActionsOpen] = useState(false);
    const handleActionsToggle = () => {
        setActionsOpen((prevActionsOpen) => !prevActionsOpen);
    };

    const handleActionsClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setActionsOpen(false);
    };

    function handleActionsListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setActionsOpen(false);
        } else if (event.key === 'Escape') {
            setActionsOpen(false);
        }
    }
    return (
        <>
        <Card
                sx={{ maxWidth: 300, margin: '1rem', textAlign: 'left' }}
                className={selected ? 'cardSelected' : ''}
                id={id}>
                {/* <CardMedia
                    sx={{ height: 140 }}
                    image="/static/images/cards/contemplative-reptile.jpg"
                    title="green iguana"
                /> */}
                <CardHeader
                    avatar={avatar}
                    action={actionMenuItems &&
                        <>
                            <IconButton
                                ref={anchorRef}
                                onClick={handleActionsToggle}
                                id="composition-button"
                                aria-controls={
                                    actionsOpen ? 'composition-menu' : undefined
                                }
                                aria-expanded={actionsOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                sx={{ float: 'right', fontSize: '1rem' }}
                                aria-label="actions">
                                <MoreVertIcon />
                            </IconButton>
                            <Popper
                                open={actionsOpen}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                sx={{ zIndex: 2 }}
                                placement="bottom-start"
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start'
                                                    ? 'left top'
                                                    : 'left bottom',
                                        }}>
                                        <Paper variant="outlined">
                                            <ClickAwayListener
                                                onClickAway={
                                                    handleActionsClose
                                                }>
                                                <MenuList
                                                    autoFocusItem={actionsOpen}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={
                                                        handleActionsListKeyDown
                                                    }>
                                                        {actionMenuItems}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    }
                    title={title || ''}
                    subheader={subtitle || ''}
                    subheaderTypographyProps={{
                        fontSize: '.8rem',
                    }} />
                <Divider />
                <CardContent>
                    {children}
                </CardContent>
                
                { cardActions && <>
                    <Divider />
                    <CardActions>
                        {cardActions}
                    </CardActions>
                    </>
                }
            </Card>
        </>
    )
}


export default BaseCard;