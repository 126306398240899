import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { addGroup } from '../../../api/brrmchat/group/group';
import storeDashboard from '../../../store/dashboard/dashboard';
import { addUser } from '../../../api/brrmchat/user/user';

export default function DialogAddUser({open = true, onClose, onSubmit}) {

    const { selectedApp, selectedAppId } = storeDashboard((state) => state);
    const [ hasError, setHasError] = useState(false);
    const formDataRef = useRef({
        appId: selectedApp.id,
        name: null,
        nameError: null,
        email: null,
        emailError: null,
        password: null,
        passwordError: null,
    });

    useEffect(() => {
        console.log("uhvacena promena hasError: ", hasError);
    }, [hasError])

    const validateSubmit = () => {
        if(formDataRef?.current?.name?.length < 8) {
            formDataRef.current.nameError = "Must be at least 8 character long.";
            setHasError(true);
            return;
        } 
        if(!formDataRef?.current?.email?.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
            formDataRef.current.emailError = "Invalid email.";
            setHasError(true);
            return;
        } 
        if(formDataRef?.current?.password?.length < 8) {
            formDataRef.current.passwordError = "Must be at least 8 character long.";
            setHasError(true);
            return;
        } 
        console.log("[DialogAddGroup] before mutate: ",formDataRef?.current)
        mutationAddUser.mutate({appId: selectedApp.id, userObj: formDataRef.current});  
    }

    const mutationAddUser = useMutation({
        mutationFn: addUser,
        onSuccess: (data) => {
            console.log('Success add Group', data);
            onSubmit(data);
        },
        onError: (err) => {
            console.log('Error add Group: ', err);
            toast.error(err);
        },
    });
    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Dialog Add User</DialogTitle>
                <DialogContent>
                    <TextField
                        error={hasError && !!formDataRef.current.nameError}
                        helperText={(hasError && !!formDataRef.current.nameError) ? formDataRef.current.nameError : "" }
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.name = event.target.value 
                            setHasError(false)
                        }}
                    />
                    <TextField
                        error={hasError && !!formDataRef.current.emailError}
                        helperText={(hasError && !!formDataRef.current.emailError) ? formDataRef.current.emailError : "" }
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.email = event.target.value 
                            setHasError(false)
                        }}
                    />
                    <TextField
                        error={hasError && !!formDataRef.current.passwordError}
                        helperText={(hasError && !!formDataRef.current.passwordError) ? formDataRef.current.passwordError : "" }
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={event => {
                            formDataRef.current.password = event.target.value 
                            setHasError(false)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={() => { validateSubmit(formDataRef.current)}}>Add</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
