import React, { useEffect, useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SidebarData } from './SidebarData';
//import './navigation.css';
import { IconContext } from 'react-icons';
import logo from '../../../assets/logo192.png';
import profilePictureDefault from '../../../assets/profile_default.jpg';
import useAuthStore from '../../../store/auth';
import RoleGuard from '../../router/roleGuard';
import { USER } from '../../../constants/user';

function Navigation() {
    const pathName = useLocation().pathname;

    const navigate = useNavigate();

    const { AUTH_DATA, logoutUser } = useAuthStore((state) => state);

    const [sidebar, setSidebar] = useState(false);
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [logoutValue, setLogoutValue] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);
    const showProfileDropdown = () => setProfileDropdown(!profileDropdown);
    const callLogutUser = () => {
        logoutUser();
        localStorage.removeItem(AUTH_DATA);
        navigate('/login');
    };

    useEffect(() => {
        if (logoutValue) {
            logoutUser();
            navigate('/login');
        }
    }, [logoutValue]);

    useEffect(() => {
        window
            .matchMedia('(min-width: 768px)')
            .addEventListener('change', (e) => {
                if (!e.matches) {
                    setSidebar(false);
                } else {
                    setSidebar(true);
                }
            });
    }, []);

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className="navbar">
                    <Link to="#" className="menu-bars">
                        <FaIcons.FaBars onClick={showSidebar} />
                    </Link>
                    <div className="menu-headline">
                        <span className="headline">BrrmChat Dashboard</span>
                        <img src={logo} className="logo" />
                    </div>
                    <Link
                        to="#"
                        className="menu-profile"
                        onClick={showProfileDropdown}>
                        <img
                            src={profilePictureDefault}
                            className="profile-image"
                        />
                    </Link>

                    <ul
                        className={
                            profileDropdown
                                ? 'menu-profile-dropdown active'
                                : 'menu-profile-dropdown'
                        }>
                        <li>
                            <Link to="#">
                                <BsIcons.BsFillFilePersonFill color="inherit" />{' '}
                                Profile
                            </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={() => callLogutUser()}>
                                <BsIcons.BsXOctagonFill color="inherit" />{' '}
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <div className="navbar-toggle" onClick={showSidebar}>
                        <Link to="#" className="menu-bars">
                            <AiIcons.AiOutlineClose />
                        </Link>
                    </div>
                    <ul className="nav-menu-items" onClick={showSidebar}>
                        {SidebarData.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={[
                                        item.cName,
                                        pathName === item.path ? 'active' : '',
                                    ]
                                        .filter((e) => !!e)
                                        .join(' ')}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    );
}

export default Navigation;
