import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";


const BaseSection = ({children, title, actionButton, noChildrenMessage, subtitle}) => {


    return (
        <>
            <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                    {actionButton &&
                        <Box  sx={{float: 'right'}}>
                            {actionButton}
                        </Box>
                    }
                </Typography>
                {subtitle &&
                    <Typography gutterBottom variant="body1" component="div">
                        {subtitle}
                    </Typography>
                }
                <Divider />
                {(!children || children.length=== 0) && 
                        <Typography gutterBottom variant="body1" component="div" sx={{margin: 10}}>
                            {noChildrenMessage}
                        </Typography>
                }
                {children}
            </Paper>
        </>
    )
}


export default BaseSection;