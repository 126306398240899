import { createBrowserRouter } from "react-router-dom";
import PublicRoute from "./publicRoute";
import Login from "../login/login";
import PrivateRoute from "./privateRoute";
import Dashboard from "../dashboard/dashboard";
import Apps from "../dashboard/apps/apps";
import RoleGuard from "./roleGuard";
import { USER } from "../../constants/user";
import Groups from "../dashboard/groups/groups";
import Users from "../dashboard/users/users";


const mainRouter = createBrowserRouter([
    {
        element: <PublicRoute />,
        children: [
            {
                path: "login",
                element: <Login />,

            }
        ]
    },
    {
        element: <PrivateRoute />,
        children: [
            {
                path: "",
                element: <Dashboard />,
                children: [
                    {
                        path: "apps",
                        element: <> <RoleGuard allowedRoles={[USER.ROLE.ROOT]} renderChildren={true}><Apps /></RoleGuard></>
                    },
                    {
                        path: "users",
                        element: <> <RoleGuard allowedRoles={[USER.ROLE.ROOT, USER.ROLE.ADMIN]} renderChildren={true}><Users /></RoleGuard></>
                    },
                    {
                        path: "groups",
                        element: <> <RoleGuard allowedRoles={[USER.ROLE.ROOT, USER.ROLE.ADMIN]} renderChildren={true}><Groups /></RoleGuard></>
                    },
                    {
                        path: "*",
                        element: <><h1>Not found</h1></>
                    }
                ]
            },
            {
                path: "*",
                element: <><h1>Not found</h1></>
            }
        ]
    }
]);

export default mainRouter;