import logo from './logo.svg';
import { Outlet, RouterProvider } from 'react-router-dom';
import mainRouter from './components/router/router';
import GlobalLoading from './components/helpers/loading/globalLoading';
import 'bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { SSRProvider } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { defaultTheme } from './theme/theme';
import { ToastContainer } from 'react-toastify';

function App() {

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <div className="App ">
                    <GlobalLoading />
                    <ToastContainer 
                      position="top-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="dark"
                    />
                    <RouterProvider router={mainRouter}>
                            <IconContext.Provider>
                                <Outlet />
                            </IconContext.Provider>
                    </RouterProvider>
                </div>
            </ThemeProvider>
        </>
    );
}

export default App;
