import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import storeAuth from '../../../store/auth';
import { toast } from 'react-toastify';
import { Divider, Grid, IconButton, Paper, Skeleton, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import storeDashboard from '../../../store/dashboard/dashboard';
import { getGroups } from '../../../api/brrmchat/group/group';
import DialogAddGroup from './dialogAddGroup';
import GroupCard from '../shared/groupCard';
import { getAppUsers, getGroupUsers } from '../../../api/brrmchat/user/user';
import UserCard from '../shared/userCard';
import BaseSection from '../shared/baseSection';
import DialogAddUserToGroup from './dialogAddUserToGroup';
import EditIcon from '@mui/icons-material/Edit';

const Groups = () => {
    console.log('[Groups] rendered');
    const { selectedApp, selectedAppId } = storeDashboard((state) => state);
    const { auth, loggedInUser } = storeAuth((state) => state);

    const [selectedGroup, setSelectedGroup] = useState(false);
    const [isDialogAddGroupOpen, setIsDialogAddGroupOpen] = useState(false);
    const [isDialogAddUsersToGroupOpen, setIsDialogAddUsersToGroupOpen] = useState(false);

    console.log("[Groups] should getGroupUsers: ",(loggedInUser !== null) && (!!selectedGroup?.id))
    const queryAppGroups = useQuery({
        queryKey: ['getGroups'],
        queryFn: () => getGroups(selectedAppId),
        enabled: loggedInUser !== null && !!selectedAppId,
    });
    const queryGroupUsers = useQuery({
        queryKey: ['getGroupUsers'],
        queryFn: () => getGroupUsers(selectedGroup.id),
        enabled: ((loggedInUser !== null) && (!!selectedGroup?.id)),
    });

    const queryAppUsers = useQuery({
        queryKey: ['appUsers'],
        queryFn: () => getAppUsers(selectedApp?.id),
        enabled: !!selectedApp?.id,
    });

    useEffect(() => {

    }, [queryAppUsers.isFetching]);

    useEffect(() => {
        if (!queryAppGroups.isFetching && queryAppGroups.isError) {
            toast.error('Error getting groups.');
        } else if (
            !queryAppGroups.isFetching &&
            !queryAppGroups.isError &&
            queryAppGroups.data
        ) {
            console.log('getovano groups: ', queryAppGroups.data);
        }
    }, [queryAppGroups.isFetching]);

    useEffect(() => {
        if (!queryGroupUsers.isFetching && queryGroupUsers.isError) {
            toast.error('Error getting group users.');
        } else if (
            !queryGroupUsers.isFetching &&
            !queryGroupUsers.isError &&
            queryGroupUsers.data
        ) {
            console.log('getovano group users: ', queryGroupUsers.data);
        }
    }, [queryGroupUsers.isFetching]);


    useEffect(() => {
        queryAppGroups.refetch();
    }, [selectedAppId]);
    
    useEffect(() => {
        if(selectedGroup && selectedGroup.id) {
            queryGroupUsers.refetch();
        }
    }, [selectedGroup]);

    const handleAddGroup = () => {
        setIsDialogAddGroupOpen(true);
    };

    const handleAddGroupClose = () => {
        setIsDialogAddGroupOpen(false);
    };

    const handleAddGroupSubmit = (groupData) => {
        console.log('AddApp Dialog submit: ', groupData);
        setIsDialogAddGroupOpen(false);
        queryAppGroups.refetch();
    };

    const handleGroupSelected = (groupId) => {
        console.log('[Groups] selected groupId: ', groupId);
    };

    const handleGroupUsersSelected = (selectedGroup) => {
        console.log('[Groups] selected see Users for groupId: ', selectedGroup?.id);
        setSelectedGroup(selectedGroup);
    }

    const handleAddUser = () => {
        setIsDialogAddUsersToGroupOpen(true);
    }
    const handleAddUserGroupClose = () => {
        setIsDialogAddUsersToGroupOpen(false);
    };

    const handleAddUserGroupSubmit = (data) => {
        console.log('Add users to group Dialog submit: ', data);
        setIsDialogAddUsersToGroupOpen(false);
        queryGroupUsers.refetch();
    };

    return (
        <>
            <DialogAddGroup
                key={"dialog-add-group"}
                open={isDialogAddGroupOpen}
                onClose={handleAddGroupClose}
                onSubmit={handleAddGroupSubmit}
            />
            <DialogAddUserToGroup
                key={"dialog-add-users-to-group"}
                open={isDialogAddUsersToGroupOpen}
                onClose={handleAddUserGroupClose}
                onSubmit={handleAddUserGroupSubmit}
                groupId={selectedGroup?.id}
                usersFromGroup={queryGroupUsers?.data}
                appUsers={queryAppUsers?.data}
            />
            <BaseSection
                key={"section-groups"}
                actionButton={
                    <IconButton
                        onClick={handleAddGroup}
                        sx={{ float: 'right' }}>
                        <AddCircleIcon />
                    </IconButton>
                }
                title={'Groups'}
                noChildrenMessage={"No groups, feel free to create them."}
            >
                {queryAppGroups?.data?.length > 0 && 
                    <Grid container spacing={1} xs={12} item sx={{alignItems: 'center'}}>
                        {queryAppGroups?.data?.map((group) => (
                            <>
                                <GroupCard
                                    key={group?.id}
                                    groupObj={group}
                                    onGroupSelected={handleGroupSelected}
                                    selected={selectedGroup.id === group.id}
                                    onGroupUsersSelected={handleGroupUsersSelected}
                                />
                            </>
                        ))}
                    </Grid>
                }
            </BaseSection>

            {selectedGroup?.id && 
                (<>
                <BaseSection
                    actionButton={
                        <IconButton
                            onClick={handleAddUser}
                            sx={{ float: 'right' }}>
                            <EditIcon />
                        </IconButton>
                    }
                    title={`${selectedGroup?.title} users`}
                    noChildrenMessage={"No users in this group."}
                >
                    {queryGroupUsers?.data?.length > 0 && 
                        <Grid container spacing={1} xs={12} item>
                            {queryGroupUsers?.data?.map((user) => (
                                <>
                                    <UserCard key={user.id} userObj={user}/>
                                </>
                            ))}
                        </Grid>
                    }

                </BaseSection>
                </>)
            }
        </>
    );
};

export default Groups;
