import axios from "../axios";
 
export async function getUserSelf(userId) {
    //return new Promise((res) => setTimeout(res({}), 5000))
    //console.log("AXIOS", axios);
    const result = await axios.get(
        `/users/${userId}`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return result.data;
}


export async function getGroupUsers(groupId) {
    //return new Promise((res) => setTimeout(res({}), 5000))
    //console.log("AXIOS", axios);
    const result = await axios.get(
        `/groups/${groupId}/users`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return result.data;
}


export async function getAppUsers(appId) {
    //return new Promise((res) => setTimeout(res({}), 5000))
    //console.log("AXIOS", axios);
    const result = await axios.get(
        `/apps/${appId}/users`,
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    return result.data;
}

export async function addUser({appId, userObj}) {
  const result = await axios.post(
    `/apps/${appId}/users`,
    {
      userObj,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return result.data;
}

export async function addUsersToGroup({groupId, userIds}) {
    const result = await axios.post(
      `/groups/${groupId}/users`,
      {
        userIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
}

export async function removeUsersFromGroup({groupId, userIds}) {
    const result = await axios.delete(
      `/groups/${groupId}/users`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userIds,
        }
      }
    );
    return result.data;
}