import React from 'react';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Skeleton,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PeopleIcon from '@mui/icons-material/People';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GroupsIcon from '@mui/icons-material/Groups';
import { useState, useRef } from 'react';
import profilePictureDefault from '../../../assets/profile_default.jpg';

const UserCard = ({
    userObj,
    onGroupSelected,
    selected = false,
    onGroupUsersSelected,
}) => {
    const handleEditGroup = () => {
        console.log('[GroupCard] edit clicked: ', userObj);
    };
    const [actionsOpen, setActionsOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleActionsToggle = () => {
        setActionsOpen((prevActionsOpen) => !prevActionsOpen);
    };

    const handleActionsClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setActionsOpen(false);
    };

    function handleActionsListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setActionsOpen(false);
        } else if (event.key === 'Escape') {
            setActionsOpen(false);
        }
    }

    const handleActionEdit = () => {};

    const handleActionDelete = () => {};

    return (
        <>
            <Card
                sx={{ maxWidth: 300, margin: '1rem', textAlign: 'left' }}
                className={selected ? 'cardSelected' : ''}
                id={userObj?.id}>
                {/* <CardMedia
                sx={{ height: 140 }}
                image="/static/images/cards/contemplative-reptile.jpg"
                title="green iguana"
            /> */}
                <CardHeader
                    avatar={
                        <Avatar
                            alt={userObj?.name}
                            src={profilePictureDefault}
                        />
                    }
                    action={
                        <>
                            <IconButton
                                ref={anchorRef}
                                onClick={handleActionsToggle}
                                id="composition-button"
                                aria-controls={
                                    actionsOpen ? 'composition-menu' : undefined
                                }
                                aria-expanded={actionsOpen ? 'true' : undefined}
                                aria-haspopup="true"
                                sx={{ float: 'right', fontSize: '1rem' }}
                                aria-label="actions">
                                <MoreVertIcon />
                            </IconButton>
                            <Popper
                                open={actionsOpen}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                sx={{ zIndex: 2 }}
                                placement="bottom-start"
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start'
                                                    ? 'left top'
                                                    : 'left bottom',
                                        }}>
                                        <Paper variant="outlined">
                                            <ClickAwayListener
                                                onClickAway={
                                                    handleActionsClose
                                                }>
                                                <MenuList
                                                    autoFocusItem={actionsOpen}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={
                                                        handleActionsListKeyDown
                                                    }>
                                                    <MenuItem
                                                        onClick={
                                                            handleActionEdit
                                                        }
                                                        disableRipple>
                                                        <EditIcon />
                                                        Edit
                                                    </MenuItem>
                                                    <Divider sx={{ my: 0.5 }} />
                                                    <MenuItem
                                                        onClick={
                                                            handleActionDelete
                                                        }
                                                        disableRipple>
                                                        <DeleteForeverIcon />
                                                        Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    }
                    title={userObj?.name}
                    subheader={userObj?.email}
                    subheaderTypographyProps={{
                        fontSize: '.8rem',
                    }}></CardHeader>
                <Divider />
                {/* <CardContent>
                    <List sx={{ width: '100%' }}>
                        <ListItem key={"name"}>
                            <ListItemText primary="name: " />
                            {userObj ? (
                                <ListItemText primary={userObj?.name} />
                            ) : (
                                <Typography
                                    variant="body2"
                                    color="text.secondary">
                                    {userObj?.uniqueId}
                                </Typography>
                            )}
                        </ListItem>
                        <ListItem key={"email"}>
                            <ListItemText primary="email: " />
                            {userObj ? (
                                <ListItemText primary={userObj?.email} />
                            ) : (
                                <Typography
                                    variant="body2"
                                    color="text.secondary">
                                    {userObj?.uniqueId}
                                </Typography>
                            )}
                        </ListItem>
                    </List>
                </CardContent> */}
                {/*<Divider />
                 <CardActions>
                    {userObj ? (
                        <>
                            <Button
                                size="small"
                                onClick={() => onGroupUsersSelected(userObj)}>
                                <PeopleIcon />
                            </Button>
                        </>
                    ) : (
                        <>
                            <Skeleton animation="wave" height={1} width="10%" />
                        </>
                    )}
                </CardActions> */}
            </Card>
        </>
    );
};

export default UserCard;
