import axios from "../axios";

export async function authLogin(
  appAccessToken,
  uniqueId,
  email,
  name,
  groupUniqueId,
  groupName
) {
  //return new Promise((res) => setTimeout(res({}), 5000))
  //console.log("AXIOS", axios);
  const result = await axios.post(
    `/appAuth`,
    {
      appAccessToken,
      user: {
        uniqueId,
        email,
        name,
        groupUniqueId,
        groupName,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return result.data;
}

export async function login({email, password}) {
  //return new Promise((res) => setTimeout(res({}), 5000))
  //console.log("AXIOS", axios);
  const result = await axios.post(
    `/login`,
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return result.data;
}
