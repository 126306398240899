import { create } from 'zustand';

const DASHBOARD_DATA_APP = 'pdjkvndj39gfnh489fh348';

const getApp = () => {
    let app = null;
    try {
    app =
        JSON.parse(localStorage.getItem(DASHBOARD_DATA_APP)) ||
        null;
    } catch(error) {
        app = {
            id: null,
            name: null,
        }
    }

    return {
        id: app?.id || null,
        name: app?.name || null,
    };
};

const storeDashboard = create((set, get) => ({
    selectedAppId: getApp().id,
    selectedApp: getApp(),
    setApp: (data) => {
        set(() => ({
            selectedAppId: data?.id,
            selectedApp: { id: data?.id || null, name: data?.name || null},
        }))
        if(data == null) {
            localStorage.removeItem(DASHBOARD_DATA_APP);
        } else {
            localStorage.setItem(DASHBOARD_DATA_APP, JSON.stringify(get().selectedApp));
        }
    },

}));

export default storeDashboard;
